import React from 'react';
import { Box, Stack, IconButton, Grid, Input, Typography, Button, Paper, experimentalStyled as styled, Hidden } from "@mui/material";
import { FC, useEffect, useState, useRef, useImperativeHandle } from "react";
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"

const StageItemNone = (props) => {

  const ButtonItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const topAreaHeight = 12
  const addMargin = 3
  const triangleMargin = 2
  const triangleHeight = 5
  const stageWidth = props.singleStageWidth

  return (

    <div style={{ 
      width: props.actualStageWidth + props.stageDiv, 
      height: (topAreaHeight + addMargin + props.singleStageHeight + triangleMargin + triangleHeight),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: -2,
      zIndex:1,
    }}>
      <Stack direction="row"
        spacing={0}>

        <div style={{
          width: stageWidth,
          height: (topAreaHeight + addMargin + props.singleStageHeight + triangleMargin + triangleHeight),
          position: "relative",
        }}>

          <ButtonItem sx={{
            width: stageWidth,
            height: props.singleStageHeight,
            position: "absolute",
            top: props.archType === 0 ? (topAreaHeight + addMargin) : (triangleHeight + triangleMargin),
            // alignItems: 'center',
            // justifyContent: 'center',
            border: 'solid 1px #414141',
            color: "black",
            backgroundColor: 'white',
            fontSize: 14,
            userSelect: 'none',
            zIndex: 0,
          }}
          >

            <Box sx={{
              width: 6,
              height: 6,
              backgroundColor: "transparent",
              borderRadius: 3,
              margin: '0 auto',
              position: "absolute",
              left: ((stageWidth - 2) / 2) - (6 / 2),
              top: props.archType === 0 ? 2 : (props.singleStageHeight - 3 - 6 - 1)
            }}>
            </Box>

            <span style={{
              userSelect: "none",
              width: stageWidth - 2,
              height: 16,
              fontSize: 15,
              position: "absolute",
              left: 0,
              bottom: props.archType === 0 ? 1 : (props.singleStageHeight - 2 - 16 - 2),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: "black",
            }}>
              {props.nameStr}
            </span>
          </ButtonItem>

        </div>

      </Stack>
    </div>

    
  );
};

export default StageItemNone;