import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useLocation } from "react-router-dom"

import { Box, Grid } from "@/components/mui.components"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import {
  resetSchema,
  updatePrescriptionData,
  updatePrescriptionSchemaArches,
  updaterxFormData,
  updateRxFormSchema,
} from "@/core/app/slices/uassist/formSlice"
import {
  fetchFormByCaseIdWithMergeData,
  updateFormSavedData,
} from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { checkIfObjectHasKeys } from "@/core/utils/formatters"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import JsonFormBuilder from "@/rx-form/builder/JsonFormBuilder"

import { NoUassistPopup } from "./NoUassistPopup"
import Summary from "./Summary"
import FormOk from "./FormOk"
import {
  getCaseById,
  updateCaseStatus,
} from "@/core/app/slices/case/caseThunkApi"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"
import { updateOrder } from "@/core/app/slices/order/orderThunkApi"
import FORM_CONSTANT from "@/core/app/slices/uassist/form.constant"
import { getFormByName } from "@/core/app/slices/uassist/form.util"
import { UText } from "@/ui-component/components"
const Retainer: FC<{
  setUpperArchType?: (val: number) => void
  setLowerArchType?: (val: number) => void
  postSettingTozips?: () => void
  reviewNum?: number
  setActionType?: (val: string) => void
  actionType?: string
  needToSubmit?: boolean
}> = ({
  setUpperArchType,
  setLowerArchType,
  postSettingTozips,
  reviewNum,
  setActionType,
  actionType,
  needToSubmit,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [downloadPDF, setDownloadPDF] = useState<boolean>(false)
  const [error, setError] = useState([])
  const { patientId, caseId } = useParams()
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [showNoUassistAlert, setShowUNoUassistAlert] = useState<boolean>(false)

  const { formTemplateList, rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const handleDataChange = (data: any, errors: any) => {
    dispatch(updatePrescriptionData(data))
    setUpperArchType &&
      setUpperArchType(data.arches_to_treat.includes("upper") ? 1 : null)
    setLowerArchType &&
      setLowerArchType(data.arches_to_treat.includes("lower") ? 1 : null)
    if (!errors.length) return
    const currError = errors[0]
    const index = error.findIndex(
      (err) => err.instancePath === currError.instancePath,
    )
    if (currError.instancePath === "") return
    // biteramp special handling
    if (currError.instancePath === "/add_bite_ramps") return
    if (index > -1 && currError.message === "") {
      const newErrors = error.filter((err, i) => index !== i)
      setError([...newErrors])
    } else if (index === -1 && currError.message !== "") {
      setError([...error, currError])
    }
  }

  useEffect(() => {
    let err = []
    if (rxPrescription.schema?.required?.length && !rxPrescription.saveid) {
      err = Object.keys(rxPrescription.schema.required).map((key) => {
        return { instancePath: "/" + rxPrescription.schema.required[key] }
      })
    }
    setError([...err])
  }, [rxPrescription.schema])

  useEffect(() => {
    if (reviewNum === 0 && actionType === "review" && !needToSubmit) {
      setDownloadPDF(true)
    }
  }, [needToSubmit, actionType, reviewNum])

  useEffect(() => {
    if (caseDetail && caseDetail.udesign_json && rxPrescription.schema.properties) {
      const udesign_json = JSON.parse(caseDetail.udesign_json)
      const udesignCategory = caseDetail?.udesign_category
      const arches = udesignCategory && udesignCategory.split("/")
      let arches_to_treat = {
        ...rxPrescription.schema.properties.arches_to_treat,
      }
      if (
        !(
          udesign_json.UpperType === "A" ||
          udesign_json.UpperType === "R" ||
          (arches && arches[0].includes("R")) ||
          (arches && arches[0].includes("A")) ||
          udesign_json.upperUploaded
        )
      ) {
        arches_to_treat.checkboxes = arches_to_treat.checkboxes.filter(
          (checkbox) => checkbox.value !== "upper",
        )
        dispatch(updatePrescriptionSchemaArches(arches_to_treat))
      }else if(!arches_to_treat.checkboxes.find((checkbox) => checkbox.value === "upper")) {
        arches_to_treat.checkboxes = [...arches_to_treat.checkboxes, {
          value: "upper",
          label: "Upper",
        }];
        dispatch(updatePrescriptionSchemaArches(arches_to_treat))
      }
      
      if (
        !(
          udesign_json.LowerType === "A" ||
          udesign_json.LowerType === "R" ||
          (arches && arches[1].includes("R")) ||
          (arches && arches[1].includes("A")) ||
          udesign_json.lowerUploaded
        )
      ) {
        arches_to_treat.checkboxes = arches_to_treat.checkboxes.filter(
          (checkbox) => checkbox.value !== "lower",
        )

        dispatch(updatePrescriptionSchemaArches(arches_to_treat))
      }else if(!arches_to_treat.checkboxes.find((checkbox) => checkbox.value === "lower")) {
        arches_to_treat.checkboxes = [...arches_to_treat.checkboxes, {
          value: "lower",
          label: "Lower",
        }];
        dispatch(updatePrescriptionSchemaArches(arches_to_treat))
      }
    }
  }, [caseDetail.udesign_json, rxPrescription.id])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    dispatch(
      fetchFormByCaseIdWithMergeData({
        formId: getFormByName(
          formTemplateList,
          "UASSIST",
          FORM_CONSTANT.RETAINER,
        ).id,
        caseId: caseId,
        getSavedData: true,
        patientId: patientId,
        formType: "UASSIST",
        uassistRound: caseDetail?.uassist_round || 0,
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (downloadPDF) return
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)
      return
    }
    // else if (isFormSubmitted) {
    //   if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
    //     setShowUNoUassistAlert(true)
    //   } else {
    //     dispatch(
    //       updateFormSavedData({
    //         formId: rxPrescription.id,
    //         caseId: caseId,
    //         payload: rxPrescription.data,
    //         savedId: rxPrescription.saveid,
    //       }),
    //     ).then((res) => {
    //       // if (res.payload.status === 200) {
    //       //   postSettingTozips && postSettingTozips()
    //       //   dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    //       //   dispatch(
    //       //     setAlert({
    //       //       message:
    //       //         rxPrescription?.id &&
    //       //         t("uassist.uAssistForm.retainersavesuccess"),
    //       //     }),
    //       //   )
    //       //   setDownloadPDF(true)
    //       // } else {
    //       //   dispatch(
    //       //     setAlert({
    //       //       message:
    //       //         rxPrescription?.id && t("uassist.uAssistForm.retainersavefailed"),
    //       //     }),
    //       //   )
    //       // }
    //     })
    //   }
    // }
  }, [error, isFormSubmitted, downloadPDF])
  // change to submitted
  const changeStatus = async (newStatus: string) => {
    return await dispatch(
      updateCaseStatus({
        patientId: patientId.toString(),
        caseId: caseId,
        payload: {
          case_disposition: newStatus,
        },
      }),
    )
  }
  const changeStatusToSubmitted = async (callback?: () => void) => {
    let upper = ""
    let lower = ""
    let bracket_removal = ""

    lower = rxPrescription.data.arches_to_treat?.includes("lower") ? "R" : ""
    upper = rxPrescription.data.arches_to_treat?.includes("upper") ? "R" : ""
    bracket_removal = rxPrescription.data.bracket_removal?.value?.includes("yes")
    dispatch(
      updateOrder({
        caseId: caseId,
        type: bracket_removal ? "Bracket Removal Uassist" : "Retainer Uassist",
        upper: upper,
        lower: lower,
      }),
    ).then((r) => {
      if (r && r.payload && r.payload.status === "Success") {
        callback && callback()
        dispatch(resetSchema())
        dispatch(getCaseById({ patientId, caseId }))
      } else {
        dispatch(
          setAlert({
            message: t("uassist.uAssistForm.retainersavefailed"),
          }),
        )
      }
    })
  }
  // submit retainer forms
  const submitForm = () => {
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)
      return
    } else {
      if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
        setShowUNoUassistAlert(true)
      } else {
        dispatch(
          updateFormSavedData({
            formId: rxPrescription.id,
            caseId: caseId,
            payload: rxPrescription.data,
            savedId: rxPrescription.saveid,
            isRefinement: false,
          }),
        ).then((res) => {
          if (res.payload.status === 200) {
            setDownloadPDF(true)
            if (needToSubmit) {
              // changeStatusToSubmitted(() => {
              //   setActionType("success")
              // })
            }

            // postSettingTozips && postSettingTozips()
            // dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
            // dispatch(
            //   setAlert({
            //     message:
            //       rxPrescription?.id &&
            //       t("uassist.uAssistForm.retainersavesuccess"),
            //   }),
            // )
            // setDownloadPDF(true)
          } else {
            dispatch(
              setAlert({
                message:
                  rxPrescription?.id && t("uassist.uAssistForm.retainersavefailed"),
              }),
            )
          }
        })
      }
    }
  }
  useEffect(() => {
    if (reviewNum) {
      if (!error.length) {
        setActionType("review")
      }
      setIsFormSubmitted(true)
      if (!needToSubmit) {
        if (actionType === "edit") {
          submitForm()
        }
      } else {
        if (reviewNum === 1 && actionType === "edit") {
          submitForm()
        }
      }
    }
  }, [reviewNum])
  return (
    <>
      <NoUassistPopup
        showPopup={showNoUassistAlert}
        setShowPopup={setShowUNoUassistAlert}
        setIsPdfLoading={setIsFormSubmitted}
      />
      <Grid
        container
        // flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          padding: 0,
          // justifyContent: "start",
        }}
      >
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <UText variant={"h4"} sxProp={{ textAlign: "center", mb: "16px" }}>
            {t("uassist.uAssistForm.retainerTitile")}
          </UText>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            "& .MuiGrid-container": {
              "& .MuiGrid-item": {
                maxWidth: "100%",
              },
            },
          }}
          item
        >
          {actionType === "review" ? (
            // <ReviewForm type={"retainer"} data={rxPrescription.data} />
            downloadPDF && <Summary setActionType={setActionType} isRefinement={false} isRetainer needToSubmit={needToSubmit} reviewNum={reviewNum}/>
          ) : actionType === "edit" ? (
            !!(
              checkIfObjectHasKeys(rxPrescription.schema) &&
              checkIfObjectHasKeys(rxPrescription.uischema) &&
              checkIfObjectHasKeys(rxPrescription.data)
            ) && (
              <JsonFormBuilder
                schema={rxPrescription.schema}
                uischema={rxPrescription.uischema}
                defaultData={rxPrescription.data}
                handleChange={handleDataChange}
                isFormSubmitted={isFormSubmitted}
                setIsFormSubmitted={setIsFormSubmitted}
              />
            )
          ) : (
            <FormOk />
          )}
        </Grid>
        {/* <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box>
            {!location.pathname.includes(
              `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
            ) && (
              <Button
                variant={"outlined"}
                onClick={() => {
                  navigate(
                    `/uassist/patient/${patientId}/case/${caseId}/ordertype`,
                  )
                }}
                sx={{ width: "96px", height: "36px" }}
              >
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
                {t("button.back")}
              </Button>
            )}
          </Box>

          <Button
            variant={"contained"}
            onClick={() => {
              setIsFormSubmitted(true)
            }}
            sx={{ width: "196px", height: "36px" }}
          >
            {t("button.submittoUassist")}
            <ChevronRight
              fontSize={"small"}
              sx={{
                height: "20px",
                width: "20px",
                marginLeft: "8px",
              }}
            />
          </Button>
        </Grid> */}
      </Grid>
      {/* {downloadPDF && (
        <Box sx={{ zIndex: -1, position: "fixed" }}>
          <Summary isRefinement={false} isRetainer />
        </Box>
      )} */}
      {/* <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      /> */}
    </>
  )
}

export default Retainer
