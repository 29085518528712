import http from "../../../global/http"
import { API_ENDPOINTS } from "./forms.urls"

export const fetchFormList = async (orgId: string) => {
  const url = API_ENDPOINTS.getForms(orgId)
  try {
    const res = await http.get(url)
    return res
  } catch (err) {
    return err
  }
}
export const saveDefaultFormDataService = async (
  orgId: string,
  formId: string,
  payload: string,
): Promise<any> => {
  try {
    const { data } = await http.put(
      API_ENDPOINTS.updateDefaultData(orgId,formId),
      { form_default_data: payload, org_id: orgId },
    )
    return data
  } catch (e) {
    console.log(e)
  }
}


export const updateFormSavedDataService = async (
  orgId: string,
  formId: string,
  caseId: string,
  payload: any,
  userId: string,
  savedId: string,
  isRefinement: boolean,
) => {
  const url = API_ENDPOINTS.saveOrUpdateSavedData(orgId,formId,caseId, savedId)
  const body = {
    user_filled_data: payload,
    user_id: userId,
    is_refinement: isRefinement,
  }
  try {
    const res = savedId ? await http.put(url, body) : await http.post(url, body)
    return res
  } catch (err) {
    return err
  }
}

/**
 * In this method fetch from schema, uiSchema and default Data   and get data by case id
 * the merge svaedata with default data
 * @param orgId
 * @param formId
 * @param caseId
 * @returns
 */
export const getFormByIdWithMergeData = async (
  orgId: string,
  formId: string,
  caseId: string,
  getSavedData: boolean,
  formType: "SMARTRX"|"UASSIST",
  patientId:string,
  uassistRound: number,
  optionObj?: {
    isAdd: boolean
    currentReviewNum?: number
    refinementNum: number
  },
) => {
  try {
    // refinement form
    // if (optionObj) {
    //   // get schemaID
    //   const formResponse = await getFormById(orgId, formId)
    //   const formSchemaId = formResponse.form_schema.id
    //   // get saved data
    //   const savedDataResponse = await getFormDataByCaseId(
    //     orgId,
    //     formId,
    //     caseId,
    //     getSavedData,
    //     formType,
    //     patientId,
    //     formSchemaId,
    //     optionObj.isAdd ? optionObj.refinementNum : optionObj.currentReviewNum,
    //   )
    //   // if (savedDataResponse?.saved_data) {
    //   //   formResponse.default_data = savedDataResponse.saved_data
    //   //   formResponse.save_id = savedDataResponse.id
    //   // }
    //   // const res = await Promise.all([
    //   //   getFormById(orgId, formId),
    //   //   getFormDataByCaseId(
    //   //     orgId,
    //   //     formId,
    //   //     caseId,
    //   //     getSavedData,
    //   //     "",
    //   //     0,
    //   //     optionObj,
    //   //   ),
    //   // ])
    //   // const refineAllData = []
    //   // let dataObj = { ...res[0] }
    //   // if (res[1]) {
    //   //   // all refinement data
    //   //   res[1].map((i) => {
    //   //     const obj = {
    //   //       ...res[0],
    //   //       default_data: i.saved_data,
    //   //       save_id: i.id,
    //   //       course_check_number: i.course_check_number,
    //   //     }
    //   //     refineAllData.push(obj)
    //   //   })
    //   // }
    //   // if (optionObj.isAdd) {
    //   //   refineAllData.map((k) => {
    //   //     if (optionObj.refinementNum.toString() === k.course_check_number) {
    //   //       dataObj = k
    //   //     }
    //   //   })
    //   // } else {
    //   //   refineAllData.map((k) => {
    //   //     if (optionObj.currentReviewNum.toString() === k.course_check_number) {
    //   //       dataObj = k
    //   //     }
    //   //   })
    //   // }
    //   if (savedDataResponse?.saved_data) {
    //     formResponse.default_data = savedDataResponse.saved_data
    //     formResponse.save_id = savedDataResponse.id
    //   }
    //   return {
    //     dataObj: { ...formResponse },
    //     isAdd: optionObj.isAdd,
    //   }
    // } else {
    // get schemaID
    let formResponse
    // get saved data
    const savedDataResponse = await getFormDataByCaseId(
      orgId,
      formId,
      caseId,
      getSavedData,
      formType,
      patientId,
      formId,
      uassistRound,
    )
    if (savedDataResponse?.saved_data) {
      formResponse = await getFormById(orgId, savedDataResponse.form_schema_id)
      // const formSchemaId = formResponse.form_schema.id
      formResponse.default_data = savedDataResponse.saved_data
      formResponse.save_id = savedDataResponse.id
      formResponse.uassist_round = savedDataResponse.course_check_number // TODO: Rename course_check_number to uassist_round
    } else if (formId) {
      formResponse = await getFormById(orgId, formId)
      formResponse.uassist_round = uassistRound
    }
    return formResponse
    // }
  } catch (err) {
    return err
  }
}

export const getFormById = async (orgId: string, formSchemaId: string) => {
  const url = API_ENDPOINTS.getFormBYSchemaId(orgId, formSchemaId)
  try {
    const res = await http.get(url)
    if (res.status === 200) {
      const { data } = res
      return data.Result
    }
    return res
  } catch (err) {
    return err
  }
}

export const getFormDataByCaseId = async (
  orgId: string,
  formId: string,
  caseId: string,
  getSavedData: boolean,
  formType: "SMARTRX"|"UASSIST",
  patientId:string,
  schemaId: string,
  uassistRound: number,
  optionObj?: {
    isAdd: boolean
    currentReviewNum?: number
    refinementNum: number
  },
) => {
  let url = ""
  if (optionObj) {
    url = `/shared/v1/org/${orgId}/forms/${formId}/txplans/${caseId}/saved-data/`
  } else {
    url = API_ENDPOINTS.getFormDataByIdAndType(orgId,patientId,caseId,formType,schemaId,uassistRound)
  }
  try {
    if (optionObj) {
      const res = await http.get(url)
      if (res.status === 200) {
        const { data } = res
        return data.Result
      }
      return []
    } else {
      if (!getSavedData) return []
      const res = await http.get(url)
      if (res.status === 200) {
        const { data } = res
        return data.result || {}
      }
      return []
    }
  } catch (err) {
    return err
  }
}

export const uploadSummarypdf = async (
  orgId: string,
  patientId: string,
  caseId: string,
  payload: any,
  isRefinement: boolean,
  isRetainer?: boolean,
) => {
  try {
    const { data } = await http.post(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/uassist-summary/${
        isRefinement
          ? "refinement"
          : isRetainer
          ? "retainer-summary"
          : "summary"
      }.pdf`,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
    return data
  } catch (err) {
    return err
  }
}

export const deleteFormSavedDataService = async (
  orgId: string,
  formId: string,
) => {
  const url = API_ENDPOINTS.deleteDefaultDataSchemaId(orgId, formId)
  try {
    const res = await http.delete(url)
    return res
  } catch (err) {
    return err
  }
}
