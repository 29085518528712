import { FC } from "react"
import { Trans, useTranslation } from "react-i18next"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"

import { UButton, UText } from "../../../components"
import { Box, Grid } from "../../../components/mui.components"
import { useAppDispatch } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import { getUDAppExe } from "../../../core/app/slices/user/userApis"
import { UDApp } from "../../../core/constants/UDReleaseNote"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import usePermissions from "../../../hooks/userPermission"

const UDesignApp: FC = () => {
  const { hasAny } = usePermissions()
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()

  const getUDesignApp = (version: string) => {
    dispatch(getUDAppExe(version)).then((res) => {
      if (res.payload.data && res.payload.status === 200) {
        const url = res.payload.data.result.result
        const a = document.createElement("a")
        a.href = url
        a.download = `uDesign ${UDApp.version}.exe`
        document.body.appendChild(a)
        a.click()
        a.remove()
        window.URL.revokeObjectURL(url)
      } else {
        dispatch(
          setAlert({
            message: `Failed to download uDesign${UDApp.version}`,
            isError: true,
          }),
        )
      }
    })
  }

  const renderUText = (
    variant: any,
    textKey: string,
    sxProp?: any,
    component?: string,
    mt?: number,
  ) => (
    <UText
      variant={variant}
      component={"div"}
      key={textKey}
      sxProp={{ color: "text.secondary", ...sxProp, ...(mt && { mt }) }}
    >
      {t(textKey)}
    </UText>
  )

  const renderBoxContent = (title: string, descriptionKeys: string[]) => (
    <Box sx={{ pt: "12px" }} key={title}>
      {renderUText("subtitle2", title, {
        fontWeight: 500,
        color: "text.primary",
        marginBottom: "6px",
      })}
      <div dangerouslySetInnerHTML={{ __html: descriptionKeys }} />
    </Box>
  )

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      sx={{ minHeight: "85vh" }}
    >
      <Grid
        container
        maxWidth={"lg"}
        flexDirection={"row"}
        sx={{ justifyContent: "center" }}
      >
        <Grid
          item
          xs={12}
          sm={11}
          md={10}
          lg={10}
          xl={8}
          justifyContent="start"
          display="flex"
        >
          {renderUText("h4", "uDesignApp.header", {
            textAlign: "center",
            color: "text.primary",
            fontSize: "36px",
            fontWeight: 400,
            pb: 5,
            pt: 6,
          })}
        </Grid>
        <Grid item xs={12} sm={11} md={10} lg={10} xl={8} pb={5}>
          {hasAny(UI_PERMISSION.UI_UDESIGNAPP_DOWNLOAD) && (
            <UButton
              sxProp={{ width: "100%" }}
              onClickHandler={() => getUDesignApp(UDApp.downloadable_version)}
              variant={"contained"}
              btnText={`Download uDesign ${UDApp.version}`}
              startIcon={<CloudDownloadIcon />}
            />
          )}
        </Grid>
        <Grid
          xs={12}
          sm={11}
          md={10}
          lg={10}
          xl={8}
          item
          sx={{
            boxShadow: "0 0 2px #EEEEEE",
            p: 4,
            borderRadius: "4px",
            background: "#FFFFFF",
          }}
        >
          {renderUText("h6", "uDesignApp.appInstallation.title", {
            fontWeight: 500,
            color: "text.primary",
          })}
          <Box sx={{ mb: "24px" }}>
            {renderUText(
              "subtitle1",
              "uDesignApp.appInstallation.diagnosticHeading",
              {
                fontWeight: 500,
                pt: 2,
                pb: "4px",
                color: "text.primary",
              },
            )}
            {[
              "diagnosticDescription1",
              "diagnosticDescription2",
              "diagnosticDescription3",
              "diagnosticDescription4",
            ].map((key, index) =>
              renderUText(
                "body2",
                `uDesignApp.appInstallation.${key}`,
                { color: "text.secondary" },
                "div",
                index !== 0 ? 3 : 0,
              ),
            )}
          </Box>
          <Grid container spacing={3} flexDirection={"row"} pb={1}>
            <Grid item lg={5}>
              <Box>
                {renderUText(
                  "subtitle2",
                  "uDesignApp.appInstallation.minimumSystemRequirements",
                  { fontWeight: 500, mb: "4px", color: "text.primary" },
                )}
                {[
                  "windows",
                  "processor",
                  "processorNote",
                  "ram",
                  "hardDrive",
                  "display",
                  "tested",
                ].map((key) =>
                  renderUText("caption", `uDesignApp.appInstallation.${key}`),
                )}
              </Box>
            </Grid>
            <Grid item lg={7}>
              <Box sx={{ pt: 2 }}>
                {renderUText("subtitle2", "uDesignApp.aboutUDesign.title", {
                  fontWeight: 500,
                  mb: "4px",
                  color: "text.primary",
                })}
                {renderUText("caption", UDApp.about_version)}
                {renderUText("caption", UDApp.about_build)}
                {renderUText("caption", UDApp.date)}
                {["trademark", "Manufacturer"].map((key) =>
                  renderUText("caption", `uDesignApp.aboutUDesign.${key}`),
                )}
                <UText
                  component="div"
                  variant="caption"
                  sxProp={{ color: "text.secondary" }}
                >
                  <Trans components={{ strong: <strong /> }}>
                    {"uDesignApp.aboutUDesign.consultUDesign"}
                  </Trans>
                </UText>
              </Box>
              {/*<Box>*/}
              {/*  {renderUText(*/}
              {/*    "caption",*/}
              {/*    "uDesignApp.aboutUDesign.australiaSponsor",*/}
              {/*  )}*/}
              {/*  {renderUText(*/}
              {/*    "caption",*/}
              {/*    "uDesignApp.aboutUDesign.sponsorAddress",*/}
              {/*  )}*/}
              {/*</Box>*/}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sm={11}
          md={10}
          lg={10}
          xl={8}
          item
          sx={{
            boxShadow: "0 0 2px #EEEEEE",
            p: 3,
            borderRadius: "4px",
            my: 6,
            pb: 3,
            background: "#FFFFFF",
          }}
        >
          {renderUText("h6", "uDesignApp.releaseNotes.title", {
            color: "text.primary",
          })}
          {UDApp.releaseNote.map((item) => {
            return renderBoxContent(item.title, item.list)
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UDesignApp
