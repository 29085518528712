import type { FC } from "react"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, Button, Tab } from "@mui/material"
import { pdf } from "@react-pdf/renderer"
import { caseManagement, toothModule } from "@/gluelayer"
import html2canvas from "html2canvas"

import DentalChart from "../../../../components/DentalChart/DentalChart"
import Drag from "../../../../components/Drag/Drag"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { fetchUserProfile } from "../../../../core/app/slices/user/userApis"
import { RootState } from "../../../../core/app/store"
import ReportPDF from "../ReportPDF/reportPdf"
import { ScreeshotReport } from "../ReportPDF/screenshotReport"

import AttachmentComponent from "./components/Attachment/attachment"
import Auxilliaries from "./components/Auxilliaries/auxilliaries"
import DentalMenu from "./components/DentalChart/DentalMenu"
import IPR from "./components/IPR/ipr"
import { reportProps } from "./type"

import Close from "../../../../assets/svgs/close.svg"
import "./report.scss"
import SvgBtn from "@/components/DarkMode/SvgButton/svgbtn"

type ToothPropertyMark =
  | "I"
  | "X"
  | "a"
  | "b"
  | "c"
  | "d"
  | "e"
  | "S"
  | "E"
  | "B"
  | "M"
  | "Cr"

const Report: FC<reportProps> = ({
  reportShow,
  setReportShow,
  toothlist,
  attachment,
  iprData,
  openReportFunc,
}) => {
  const [value, setValue] = useState("IPR")
  const [appliedTeeth, setappliedTeeth] = useState<
    { teeth: string; checked: string }[]
  >([])

  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const { id } = useAppSelector(
    (state: RootState) => state.caseService.caseDetail,
  )

  const { org_name } = useAppSelector(
    (state: RootState) => state.userService.userProfile,
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchUserProfile())
  }, [])
  useEffect(() => {
    if (!reportShow) {
      return
    }

    const toothList = toothModule.getToothList()
    const teethCheckType: { teeth: string; checked: ToothPropertyMark }[] = []

    const getPrimaryToothMark = (toothId: number) => {
      const PrimaryMasks = {
        a: [508, 509, 524, 525],
        b: [507, 510, 523, 526],
        c: [506, 511, 522, 527],
        d: [505, 512, 521, 528],
        e: [504, 513, 520, 529],
      }

      const mark = PrimaryMasks.a.includes(toothId)
        ? "a"
        : PrimaryMasks.b.includes(toothId)
        ? "b"
        : PrimaryMasks.c.includes(toothId)
        ? "c"
        : PrimaryMasks.d.includes(toothId)
        ? "d"
        : PrimaryMasks.e.includes(toothId)
        ? "e"
        : undefined
      return mark
    }

    const getToothPropertyMark = (toothId: number) => {
      const toothProperties = toothModule.getToothProperties(toothId)
      const type = toothProperties.isBridge
        ? "B"
        : toothProperties.isCrown
        ? "Cr"
        : toothProperties.isErupting
        ? "E"
        : // : toothProperties.isExtract
        // ? "X"
        toothProperties.isImplant
        ? "I"
        : toothProperties.isPrimary
        ? getPrimaryToothMark(toothId)
        : undefined
      return type
    }

    const getToothProperyMask = (toothId: number) => {
      let type = undefined
      const toothIdArray = toothId <= 16 ? toothList.uplist : toothList.lowlist
      const primaryId = 500 + toothId
      const superNumerary = 700 + toothId
      if (toothIdArray.includes(superNumerary)) {
        return type
      } else if (toothIdArray.includes(toothId)) {
        type = getToothPropertyMark(toothId)
      } else if (toothIdArray.includes(primaryId)) {
        type = getToothPropertyMark(primaryId)
      } else {
        type = "M"
      }
      return type
    }

    for (let id = 1; id <= 8; id++) {
      const type: ToothPropertyMark = getToothProperyMask(id)
      if (type) {
        teethCheckType.push({ teeth: `ul${9 - id}`, checked: type })
      }
    }

    for (let id = 9; id <= 16; id++) {
      const type: ToothPropertyMark = getToothProperyMask(id)
      if (type) {
        teethCheckType.push({ teeth: `ur${id - 8}`, checked: type })
      }
    }

    for (let id = 17; id <= 24; id++) {
      const type: ToothPropertyMark = getToothProperyMask(id)
      if (type) {
        teethCheckType.push({ teeth: `dr${25 - id}`, checked: type })
      }
    }
    for (let id = 25; id <= 32; id++) {
      const type: ToothPropertyMark = getToothProperyMask(id)
      if (type) {
        teethCheckType.push({ teeth: `dl${id - 24}`, checked: type })
      }
    }

    setappliedTeeth(teethCheckType)
    // test code
    // setappliedTeeth([
    //   { teeth: "ul1", checked: "I" },
    //   { teeth: "ul2", checked: "X" },
    //   { teeth: "ul4", checked: "e" },
    //   { teeth: "ur4", checked: "S" },
    //   { teeth: "dl2", checked: "E" },
    //   { teeth: "dl4", checked: "B" },
    //   { teeth: "dr5", checked: "M" },
    //   { teeth: "dr2", checked: "Cr" },
    // ])
  }, [reportShow])

  useEffect(() => {
    if (!reportShow) {
      setValue("IPR")
    }
  }, [reportShow])
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const { t } = useTranslation("common")
  return (
    reportShow && (
      <>
        <Drag
          DragPosition={{
            x: 84,
            y: 98,
          }}
        >
          <Box className="ReportBox">
            <TabContext value={value}>
              <Box className={"tabBox"}>
                <TabList
                  onChange={handleChange}
                  aria-label="report tabs"
                  sx={{
                    "& .MuiTab-root": {
                      padding: "9px 16px",
                      fontSize: "14px",
                      fontWeight: "500",
                      minWidth: "unset",
                      maxWidth: "unset",
                      letterSpacing: "0.4px",
                      textTransform: "uppercase",
                    },
                  }}
                >
                  <Tab label="IPR" value="IPR" />
                  <Tab label="ATTACHMENT" value="ATTACHMENT" />
                  <Tab label="AUXILIARIES" value="AUXILIARIES" />
                  <Tab label="DENTAL CHART" value="DENTAL CHART" />
                </TabList>
                <Button
                  style={{
                    display: value === "DENTAL CHART" ? "none" : "unset",
                  }}
                  className={"btn print"}
                  onClick={() => {
                    openReportFunc("open")
                  }}
                />
                {/* <Button
                  className={"btn close"}
                  onClick={() => {
                    setReportShow(false)
                  }}
                /> */}
                <SvgBtn
                  boxSx={{
                    borderRadius: "16px",
                    position: "absolute !important",
                    top: "7px",
                    right: "-4px",
                    // marginTop: "8px",
                    // marginRight: "8px",
                  }}
                  svg={Close}
                  clickFun={() => {
                    setReportShow(false)
                  }}
                  svgColor="rgba(0,0,0,1)"
                ></SvgBtn>
              </Box>
              <TabPanel className={"iprContent"} value="IPR">
                <IPR toothlist={toothlist} iprData={iprData} />
              </TabPanel>
              <TabPanel value="ATTACHMENT">
                <AttachmentComponent
                  toothlist={toothlist}
                  attachment={attachment}
                />
              </TabPanel>
              <TabPanel value="AUXILIARIES">
                <Auxilliaries toothlist={toothlist} attachment={attachment} />
              </TabPanel>
              <TabPanel
                value="DENTAL CHART"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <DentalMenu></DentalMenu>
                <DentalChart
                  appliedTeeth={appliedTeeth}
                  isReadOnly={true}
                ></DentalChart>
              </TabPanel>
            </TabContext>
          </Box>
        </Drag>
      </>
    )
  )
}
export default Report
