import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import placeHolder from "../../../../assets/images/placeholder.png"
import { UButton, UText } from "../../../../components"
import { Box, Grid } from "../../../../components/mui.components"
import PatientBasicInfo from "../../../../containers/LandingLayout/PatientInfoHeader/PatientBasicInfo"
import { useAppSelector } from "../../../../core/app/hooks"
import { RootState } from "../../../../core/app/store"
import { ICase } from "../../../../core/model/interface/ICase"
import { IPatient } from "../../../../core/model/interface/IPatient"
import { UI_PERMISSION } from "../../../../core/utils/access/permissionConstants"
import { useMyNavigation } from "../../../../hooks/useMyNavigation"
import usePermissions from "../../../../hooks/userPermission"
import { getStatusObjByKey } from "../../config/status.config"
import { calculateAge, formatDate } from "../../PatientList/util/commonUtil"
import EditPatientNotePopup from "../EditPatientNotePopup"

const PatientInfoHeader: FC<{
  status: string
  patientData: IPatient
  caseDetail: ICase
}> = ({ status, patientData, caseDetail }) => {
  const { hasAny } = usePermissions()
  const { t } = useTranslation()
  const navigate = useMyNavigation()

  const { profile } = useAppSelector((state: RootState) => state.xrayService)

  const [age, setAge] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState("")

  const PATIENT_BASIC_INFO = [
    { label: t("overview.patientInfo.age"), value: age },
    { label: t("overview.patientInfo.born"), value: dateOfBirth },
  ]

  const [openNote, setOpenNote] = useState(false)
  const handlePopup = () => setOpenNote((x) => !x)

  // TODO : have to change the hardcoded values during api integration
  const PATIENT_ADDITIONAL_INFO = [
    { label: t("overview.patientInfo.phone"), value: "(415) 938-7714" },
    {
      label: t("overview.patientInfo.email"),
      value: "leanne.hamilton@mercedesamg.com",
    },
  ]

  const actionList = getStatusObjByKey(status)

  useEffect(() => {
    if (!patientData?.dob) return
    setAge(calculateAge(patientData?.dob))
    setDateOfBirth(formatDate(patientData?.dob))
  }, [patientData?.dob])

  return (
    <Grid
      container
      direction={"row"}
      spacing={0}
      justifyContent={"center"}
      sx={{ mb: 2 }}
    >
      <Grid item sm={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component={"img"}
          src={profile[0]?.src || placeHolder}
          alt={"Logo"}
          loading="lazy"
          sx={{ mr: 2, height: "96px", width: "96px" }}
        />
        <PatientBasicInfo patientData={patientData} caseDetail={caseDetail} />
      </Grid>
      <Grid
        item
        sm={6}
        sx={{ display: "flex", justifyContent: "space-between", pl: 3 }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "136px",
            mr: 2,
            pt: 2,
          }}
        >
          {PATIENT_BASIC_INFO.map((info) => {
            return (
              <Box
                component={"div"}
                sx={{ display: "flex", flexDirection: "row" }}
                key={info.label}
              >
                <UText
                  variant={"caption"}
                  color="rgba(0, 0, 0, 0.6)"
                  sxProp={{ width: "48px", fontWeight: "700" }}
                >
                  {info.label}
                </UText>
                <UText variant={"caption"} color="rgba(0, 0, 0, 0.6)">
                  {info.value}
                </UText>
              </Box>
            )
          })}
          <Box component={"div"} sx={{ display: "flex", flexDirection: "row" }}>
            <UText
              variant={"caption"}
              color="rgba(0, 0, 0, 0.6)"
              sxProp={{ width: "48px", fontWeight: "700" }}
            >
              {t("formfields.notes")}
            </UText>
            <UText
              variant={"caption"}
              color="rgba(0, 0, 0, 0.6)"
              onClickHandler={handlePopup}
              sxProp={{ textDecoration: "underline", cursor: "pointer" }}
            >
              {t("formfields.view")}
            </UText>
          </Box>
          <EditPatientNotePopup
            handlePopup={handlePopup}
            openNote={openNote}
            note={patientData.notes}
          />
        </Grid>
        {actionList && actionList?.showAdditionalData && (
          <Grid item sx={{ mr: 2, mt: 2 }}>
            {PATIENT_ADDITIONAL_INFO.map((info) => {
              return (
                <Box
                  key={info.label}
                  component={"div"}
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <UText
                    variant={"caption"}
                    color="rgba(0, 0, 0, 0.6)"
                    sxProp={{ width: "64px", fontWeight: "700" }}
                  >
                    {info.label}
                  </UText>
                  <UText
                    variant={"caption"}
                    color="rgba(0, 0, 0, 0.6)"
                    sxProp={{ maxWidth: "255px" }}
                  >
                    {info.value}
                  </UText>
                </Box>
              )
            })}
          </Grid>
        )}
        {hasAny(UI_PERMISSION.UI_PATIENTS_UPDATE) && (
          <Grid item>
            <UButton
              variant={"text"}
              btnText={"Edit"}
              sxProp={{ height: "30px", fontSize: "13px", p: 0 }}
              onClickHandler={() =>
                navigate(`/overview/patient/${patientData.id}/settings`)
              }
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default PatientInfoHeader
