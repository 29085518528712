import React, { FC, ReactElement } from "react"
import { useDispatch } from "react-redux"

import { Alert, Snackbar, SxProps } from "../../mui.components"
import { closeAlert } from "@/core/app/slices/alert/alertSlice"

export interface IAlertProps {
  alertContent: ReactElement | string
  alertProps?: SxProps
  showAlert: boolean
  verticalPosition?: "top" | "bottom"
  horizontalPosition?: "center" | "left" | "right"
  onClickHandler: (reason?: string) => void
  variant: "filled" | "outlined"
  severity: "error" | "warning" | "info" | "success"
  timeout?: number
}

const UAlert: FC<IAlertProps> = ({
  alertContent,
  alertProps,
  showAlert,
  onClickHandler,
  variant,
  severity,
  verticalPosition,
  horizontalPosition,
  timeout,
}) => {
  const anchorOrigin = {
    vertical: verticalPosition || "bottom",
    horizontal: horizontalPosition || "left",
  }
  const dispatch = useDispatch()


  return (
    <>
      <Snackbar
        sx={alertProps}
        open={showAlert}
        anchorOrigin={anchorOrigin}
        autoHideDuration={timeout || 10000}
        onClose={(event, reason) => {
          if (onClickHandler) {
            onClickHandler(reason)
          } else {
            dispatch(closeAlert())
          }
        }}
      >
        <Alert
          sx={{
            width: "fit-content",
            alignItems: "center",
            "& .MuiAlert-action": {
              paddingTop: "0px !important",
              alignSelf: "center",
            },
          }}
          variant={variant}
          severity={severity}
          onClose={() => {
            if (onClickHandler) {
              onClickHandler()
            } else {
              dispatch(closeAlert())
            }
          }}
        >
          {alertContent}
        </Alert>
      </Snackbar>
    </>
  )
}

export default UAlert
