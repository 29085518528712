import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"

import { UText } from "@/components/index"
import { Box, Button, Grid } from "@/components/mui.components"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"
import { setIsRouteChangeBlocked } from "@/core/app/slices/navigationPopup/navigationPopupSlice"
import FORM_CONSTANT from "@/core/app/slices/uassist/form.constant"
import { getFormByName } from "@/core/app/slices/uassist/form.util"
import {
  updatePrescriptionData,
  updatePrescriptionSchema,
  updateSchema,
} from "@/core/app/slices/uassist/formSlice"
import {
  fetchFormByCaseIdWithMergeData,
  getSmartTemplateData,
  updateFormSavedData,
} from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { checkIfObjectHasKeys, parseToObject } from "@/core/utils/formatters"
import { caseManagement, stageControl } from "@/gluelayer"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { NoUassistPopup } from "@/modules/Uassist/USmartForms/NoUassistPopup"
import JsonFormBuilder from "@/rx-form/builder/JsonFormBuilder"
import RenderSmartRxForm from "@/UDTreat/USmartForms/RenderSmartRxForm"
import Summary from "@/UDTreat/USmartForms/Summary"
import { UDTreat } from "@/UDTreat/UDTreat"
import NavigationBlockPopup from "@/components/NavigationBlockPopup/NavigationBlockPopup"

type archesToTreatType = {
  upper?: { [key: string]: any }
  lower?: { [key: string]: any }
}
const FormRenderer: FC<{
  needToSubmit?: boolean
  reviewNum?: number
  setActionType?: (val: string) => void
  actionType?: string
  currentReviewNum?: number
  isAdd?: boolean
  setStep?: (val: number) => void
  handleBack?: () => void
}> = ({
  needToSubmit = true,
  reviewNum = 0,
  setActionType = () => {},
  actionType = "",
  currentReviewNum,
  isAdd,
  setStep,
  handleBack
}) => {
  const { clinicalSettings } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { refinementNum, stageObjBySlice } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { upList, downList } = stageObjBySlice
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false)
  const [schemaWithNumberOfAligners, setSchemaWithNumberOfAligners] = useState(
    {},
  )
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const [error, setError] = useState([])
  const { patientId, caseId } = useParams()
  const [downloadPDF, setDownloadPDF] = useState<boolean>(false)
  const [showNoUassistAlert, setShowUNoUassistAlert] = useState<boolean>(false)
  const [showRXForm, setShowRXForm] = useState<string | null>(null)
  const { formTemplateList, rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )

  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )
  const { caseList, caseDetail } = useAppSelector(
    (state: RootState) => state.caseService,
  )
  const { uassist_progress } = caseDetail
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)
  const handelTemplateAction = (template: { id: string; value: string }) => {
    setShowRXForm(template.id)
    setActionType("smartRX")
  }

  useEffect(() => {
    let err = []
    const getUassistFormIdByName = (name: string) =>
      getFormByName(formTemplateList, "UASSIST", name).id

    if (
      rxPrescription.id ===
        getUassistFormIdByName(FORM_CONSTANT.REFINEMENT).id ||
      rxPrescription.id === getUassistFormIdByName(FORM_CONSTANT.RETAINER).id
    ) {
      if (rxPrescription.schema?.required?.length && !rxPrescription.saveid) {
        err = Object.keys(rxPrescription.schema.required).map((key) => {
          return {
            instancePath: "/" + rxPrescription.schema.required[key],
          }
        })
      }
    } else {
      const temTmp = [
        "deep_bite_template_instructions",
        "spacing_template_instructions",
        "crowding_template_instructions",
        "open_bite_template_instructions",
        "limited_treatment_template_instructions",
      ]
      if (rxPrescription.schema?.required?.length && !rxPrescription.saveid) {
        err = Object.keys(rxPrescription.schema.required).map((key) => {
          if (!temTmp.includes(rxPrescription.schema.required[key])) {
            return {
              instancePath: "/" + rxPrescription.schema.required[key],
            }
          }
        })
      }
    }
    const filteredErr = err.filter((item) => item !== undefined)
    setError([...filteredErr])

    // update the default stage number
    if (caseList[0]?.udesign_json && rxPrescription.id) {
      const obj = JSON.parse(caseList[0].udesign_json)
      const udesign_json = JSON.parse(caseList[0].udesign_json)
      const tempenum = []
      const temparches = {
        ...rxPrescription.schema.properties?.arches_to_treat?.properties,
      }
      let archesToTreat: archesToTreatType
      if (udesign_json.UpperType === "A" || udesign_json.UpperType === "R") {
        tempenum.push({ name: "Upper", enum: [0] })
        archesToTreat = { ...archesToTreat, upper: temparches?.upper }
      }
      if (udesign_json.LowerType === "A" || udesign_json.LowerType === "R") {
        tempenum.push({ name: "Lower", enum: [0] })
        archesToTreat = { ...archesToTreat, lower: temparches?.lower }
      }
      const upperStage = udesign_json.UpperMaxStageNum
      const lowerStage = udesign_json.LowerMaxStageNum

      tempenum.forEach((item) => {
        if (item.name === "Upper") {
          if (upperStage) {
            for (let i = 1; i <= upperStage; i++) {
              item.enum.push(i)
            }
          }
        } else {
          if (lowerStage) {
            for (let i = 1; i <= lowerStage; i++) {
              item.enum.push(i)
            }
          }
        }
      })
      const { properties } = rxPrescription.schema

      // Helper function to update properties
      const updateProperties = (updates) => ({
        ...properties,
        ...updates,
      })

      // Get form IDs for reuse
      const refinement2Id = getFormByName(
        formTemplateList,
        "UASSIST",
        FORM_CONSTANT.REFINEMENT_2,
      ).id
      const prescriptionId = getFormByName(
        formTemplateList,
        "UASSIST",
        FORM_CONSTANT.PRESCRIPTION,
      ).id
      const prescription2Id = getFormByName(
        formTemplateList,
        "UASSIST",
        FORM_CONSTANT.PRESCRIPTION_2,
      ).id
      const refinementId = getFormByName(
        formTemplateList,
        "UASSIST",
        FORM_CONSTANT.REFINEMENT,
      ).id
      const retainerId = getFormByName(
        formTemplateList,
        "UASSIST",
        FORM_CONSTANT.RETAINER,
      ).id

      if (rxPrescription.id === refinement2Id) {
        const updatedProperties = updateProperties({
          current_aligner_number: {
            ...rxPrescription.schema.properties?.current_aligner_number,
            inputGroup: tempenum,
          },
          arches_to_treat: {
            ...rxPrescription.schema.properties?.arches_to_treat,
            properties: archesToTreat,
          },
          smart_rx_template: {
            ...rxPrescription.schema.properties?.smart_rx_template,
            options: {
              ...rxPrescription.schema.properties?.smart_rx_template?.options,
              handelBtnAction: handelTemplateAction,
            },
          },
        })

        dispatch(updateSchema(updatedProperties))
      }

      if (
        rxPrescription.id === prescriptionId ||
        rxPrescription.id === prescription2Id
      ) {
        const updatedProperties = updateProperties({
          arches_to_treat: {
            ...rxPrescription.schema.properties?.arches_to_treat,
            properties: archesToTreat,
          },
          smart_rx_template: {
            ...rxPrescription.schema.properties?.smart_rx_template,
            options: {
              ...rxPrescription.schema.properties?.smart_rx_template?.options,
              handelBtnAction: handelTemplateAction,
            },
          },
        })
        dispatch(updateSchema(updatedProperties))
      }

      if (
        rxPrescription.id === refinementId ||
        rxPrescription.id === retainerId
      ) {
        const udesignCategory = caseList[0]?.udesign_category
        const arches = udesignCategory && udesignCategory.split("/")
        let tempschema = {
          ...rxPrescription.schema.properties.arches_to_treat,
        }

        if (tempschema) {
          let filteredCheckboxes = [...tempschema.checkboxes]

          if (arches && arches[0] !== "A" && arches[0] !== "R") {
            filteredCheckboxes = filteredCheckboxes.filter(
              (item) => item.label !== "Upper",
            )
          }
          if (arches && arches[1] !== "A" && arches[1] !== "R") {
            filteredCheckboxes = filteredCheckboxes.filter(
              (item) => item.label !== "Lower",
            )
          }

          tempschema = {
            ...tempschema,
            checkboxes: filteredCheckboxes,
          }
          if (rxPrescription.id === refinementId) {
            const updatedProperties = updateProperties({
              current_aligner_number: {
                ...rxPrescription.schema.properties?.current_aligner_number,
                inputGroup: tempenum,
              },
              arches_to_treat: tempschema,
            })
            dispatch(updateSchema(updatedProperties))
          } else {
            const updatedProperties = updateProperties({
              arches_to_treat: tempschema,
            })

            dispatch(updateSchema(updatedProperties))
          }
        }
      }
    }
  }, [caseList, rxPrescription.id, formTemplateList])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    if (caseDetail?.id) {
      dispatch(
        fetchFormByCaseIdWithMergeData({
          // formId: getFormByName(
          //   formTemplateList,
          //   "UASSIST",
          //   FORM_CONSTANT.REFINEMENT,
          // ).id,
          caseId: caseId,
          getSavedData: true,
          patientId: patientId,
          formType: "UASSIST",
          uassistRound: caseDetail?.uassist_round || 0,
        }),
      )
    }
  }, [dispatch, caseDetail])

  useEffect(() => {
    if (rxPrescription?.data?.stage_ipr) {
      if (
        rxPrescription?.data?.stage_ipr === "Not applicable" &&
        error.length
      ) {
        setError(
          error.filter(
            (item) =>
              item.instancePath !== "/stage_ipr" &&
              item.instancePath !== "/max_interproximal_conatct",
          ),
        )
      }
    }
  }, [rxPrescription?.data?.stage_ipr])
  // ???
  useEffect(() => {
    if (
      ((error &&
        error.length &&
        !error.find((e) => e.instancePath === "/current_aligner_number")) ||
        (error && !error.length)) &&
      rxPrescription?.data?.current_aligner_number
    ) {
      if (
        Number(rxPrescription?.data?.current_aligner_number?.Upper) === 0 &&
        Number(rxPrescription?.data?.current_aligner_number?.Lower) === 0
      ) {
        const errorObj = {
          instancePath: "/current_aligner_number",
        }
        setError([...error, errorObj])
      }
    }
  }, [
    rxPrescription?.data?.current_aligner_number?.Upper,
    rxPrescription?.data?.current_aligner_number?.Lower,
    error,
  ])

  // get template specific instructions
  useEffect(() => {
    if (rxPrescription.data.smart_rx_template_id) {
      dispatch(
        getSmartTemplateData(rxPrescription.data.smart_rx_template_id.id),
      ).then((res) => {
        if (res) {
          const { payload } = res
          const { default_data } = payload
          const smartRxData = parseToObject(default_data)
          if (Object.keys(smartRxData).length) {
            const currentSmartData = smartRxData.case_specific_instructions
            const currentSmart =
              rxPrescription.data.smart_rx_template.toLowerCase()
            const currentSmartKey =
              currentSmart.split(" ").join("_") + "_template_instructions"

            const obj = { ...rxPrescription.data }
            if (obj[currentSmartKey] !== currentSmartData) {
              obj[currentSmartKey] = currentSmartData
              dispatch(updatePrescriptionData({ ...obj }))
            }
          }
        }
      })
    }
  }, [rxPrescription.data.smart_rx_template_id])

  const handleDataChange = (data: any, errors: any) => {
    console.log(error, "...errors", errors)
    if (
      error &&
      error.length &&
      error.some((e) => e.instancePath === "/current_aligner_number")
    ) {
      if (
        data?.current_aligner_number?.Upper ||
        data?.current_aligner_number?.Lower
      ) {
        const updatedError = error.filter(
          (e) => e.instancePath !== "/current_aligner_number",
        )
        setError(updatedError)
      }
    }
    console.log(data)
    dispatch(updatePrescriptionData(data))

    if (!errors.length) return
    const currError = errors[0]
    const index = error.findIndex(
      (err) => err.instancePath === currError.instancePath,
    )
    if (currError.instancePath === "") return
    if (index > -1 && currError.message === "") {
      const newErrors = error.filter((err, i) => index !== i)
      setError([...newErrors])
    } else if (index === -1 && currError.message !== "") {
      setError([...error, currError])
    }
  }
  const saveform = async (formSubmit) => {
    if (downloadPDF) return
    if (error.length) {
      const getFocusId = setTimeout(() => {
        const errorElms = document.getElementsByClassName("Mui-error")
        if (errorElms.length > 0) {
          errorElms[0].scrollIntoView({ behavior: "smooth" })
        }
        clearTimeout(getFocusId)
      }, 100)
    } else if (formSubmit) {
      const refinement1 =
        rxPrescription.id ===
        getFormByName(formTemplateList, "UASSIST", FORM_CONSTANT.REFINEMENT).id
      const refinement2 =
        rxPrescription.id ===
        getFormByName(formTemplateList, "UASSIST", FORM_CONSTANT.REFINEMENT_2)
          .id
      if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
        setShowUNoUassistAlert(true)
      } else {
        if (refinement1 || refinement2) {
          let isUpperArchPresent = null
          let isLowerArchPresent = null
          if (
            rxPrescription.id ===
            getFormByName(
              formTemplateList,
              "UASSIST",
              FORM_CONSTANT.REFINEMENT_2,
            ).id
          ) {
            isUpperArchPresent = rxPrescription?.data?.arches_to_treat?.upper
            isLowerArchPresent = rxPrescription?.data?.arches_to_treat?.lower
          } else {
            isUpperArchPresent =
              rxPrescription?.data?.arches_to_treat?.includes("upper")
            isLowerArchPresent =
              rxPrescription?.data?.arches_to_treat?.includes("lower")
          }
          const isarchSaved = await caseManagement.saveDoRefinementStage(
            !isUpperArchPresent
              ? "-1010"
              : rxPrescription?.data?.current_aligner_number?.Upper,
            !isLowerArchPresent
              ? "-1010"
              : rxPrescription?.data?.current_aligner_number?.Lower,
          )
        }
        caseManagement
          .saveJsonDataToZip(
            JSON.stringify(clinicalSettings),
            "uassist_team_settings.json",
            "Setting Data2",
          )
          .then((res) => {
            if (res) {
              caseManagement.getCaseFiles(["bulk2", "bulk10"]).then((r) => {
                const zips = []
                if (r) {
                  for (const k in r) {
                    zips.push({
                      fileName: k,
                      file: r[k],
                    })
                  }
                  dispatch(
                    uploadCaseZips({
                      zips,
                      patientId,
                      caseId,
                    }),
                  ).then(() => {
                    dispatch(
                      updateFormSavedData({
                        formId: rxPrescription.id,
                        caseId: caseId,
                        payload: rxPrescription.data,
                        savedId: rxPrescription.saveid,
                        isRefinement: true,
                      }),
                    ).then((res) => {
                      if (res.payload.status === 200 && rxPrescription?.id) {
                        dispatch(
                          setIsRouteChangeBlocked({
                            isRouteChangeBlocked: false,
                          }),
                        )
                        dispatch(
                          setAlert({
                            message: t(
                              "uassist.uAssistForm.prescriptionSaveStatusSuccess",
                            ),
                          }),
                        )
                        setDownloadPDF(true)
                      } else {
                        dispatch(
                          setAlert({
                            message:
                              rxPrescription?.id &&
                              t(
                                "uassist.uAssistForm.prescriptionSaveStatusError",
                              ),
                          }),
                        )
                      }
                    })
                  })
                }
              })
            }
          })
      }
    }
  }

  return (
    <>
      {/* <div style={{ display: "none" }}>
        <UDTreat />
      </div> */}
      <NoUassistPopup
        showPopup={showNoUassistAlert}
        setShowPopup={setShowUNoUassistAlert}
        setIsPdfLoading={setIsFormSubmitted}
      />
      <Grid
        container
        flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          paddingTop: 0,
          justifyContent: "start",
        }}
      >
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <UText variant={"h4"} sxProp={{ textAlign: "center", mb: "16px" }}>
            {"Uassist Prescription"}
          </UText>
        </Grid>
        {!downloadPDF && (
          <Grid item>
            {!showRXForm ? (
              !!(
                checkIfObjectHasKeys(rxPrescription.schema) &&
                checkIfObjectHasKeys(rxPrescription.uischema) &&
                checkIfObjectHasKeys(rxPrescription.data)
              ) && (
                <JsonFormBuilder
                  schema={rxPrescription.schema}
                  uischema={rxPrescription.uischema}
                  defaultData={rxPrescription.data}
                  handleChange={handleDataChange}
                  isFormSubmitted={isFormSubmitted}
                  setIsFormSubmitted={setIsFormSubmitted}
                />
              )
            ) : (
              <RenderSmartRxForm
                setActionType={setActionType}
                setShowRXForm={setShowRXForm}
                formId={showRXForm}
              />
            )}
          </Grid>
        )}
        {downloadPDF && (
          <Box>
            <Summary
              setDownloadPdf={setDownloadPDF}
              needToSubmit={true}
              isRefinement={
                rxPrescription.name.toLowerCase().includes("refinement")
                  ? true
                  : false
              }
              isRetainer={
                rxPrescription.name.toLowerCase().includes("retainer")
                  ? true
                  : false
              }
              showSubmitBtn={true}
            />
          </Box>
        )}

        <Grid item container justifyContent={"space-between"} mt={2}>
          {rxPrescription.id && !showRXForm && !downloadPDF && (
            <>
              <Button
                variant={"outlined"}
                onClick={() => {
                    setStep && setStep(1)
                    handleBack && handleBack()
                  }
                }
                sx={{ width: "92px", height: "36px" }}
              >
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginLeft: "3px" }}
                />
                {t("button.back")}
              </Button>
              {
                <Button
                  variant={"contained"}
                  sx={{ width: "146px", height: "36px" }}
                  onClick={() => {
                    setDownloadPDF(false)
                    setIsFormSubmitted(true)
                    saveform(true)
                  }}
                >
                  {"Review"}
                  <ChevronRightIcon
                    fontSize={"small"}
                    sx={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "8px",
                    }}
                  />
                </Button>
              }
            </>
          )}
        </Grid>
        <NavigationBlockPopup
          cancelNavigation={cancelNavigation}
          confirmNavigation={confirmNavigation}
          showPrompt={showPrompt}
        />
      </Grid>
    </>
  )
}

export default FormRenderer
