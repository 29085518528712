import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"

import { store } from "./core/app/store"
import App from "./App"

import "./index.scss"

// filter the ignorable errors
const originalConsoleError = console.error;
const errFilterList = [
  "Support for defaultProps will be removed",
]

console.error = (...args) => {
  try {
    if (args.length > 0 && typeof args[0] === "string") {
      const shouldSuppress = errFilterList.some((filter) => args[0].includes(filter));
      if (!shouldSuppress) {
        originalConsoleError(...args);
      }
    } else {
      originalConsoleError(...args);
    }
  } catch (error) {
    // Error in custom console.error override
    originalConsoleError(...args);
  }
};

const contentElement = document.getElementById("root") as HTMLElement

ReactDOM.createRoot(contentElement).render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
)
