import { useAppDispatch } from "@/core/app/hooks"
import { resetPrescriptionSchema, resetSchema } from "@/core/app/slices/uassist/formSlice"
import { UButton, UModalPopup, UText } from "@/ui-component/components"
import { FC } from "react"
import { useTranslation } from "react-i18next"

const LeavePagePopup: FC<{
  showPopup: boolean
  setStep: (val: number) => void
  setShowPopup: (val: boolean) => void
}> = ({ showPopup, setStep, setShowPopup }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <UModalPopup
      isAlert={showPopup}
      title={
        <UText
          variant={"h6"}
          sxProp={{
            width: "396px",
          }}
        >
          {t("newpatient.backbtnModal.title")}
        </UText>
      }
      sxModalProps={{
        "#titleCntr": {
          padding: "16px 24px",
        },
        "#contentCntr": {
          padding: "20px 24px",
        },
        "#btnListCntr": {
          padding: 1,
          gap: 1,
        },
      }}
      content={
        <UText variant={"body1"} sxProp={{ display: "block", height: "32px" }}>
          {t("popup.changesWillNotSave")}
        </UText>
      }
      btnList={[
        <UButton
          key={t("newpatient.backbtnModal.leavePage")}
          variant="shade"
          btnText={t("newpatient.backbtnModal.leavePage")}
          onClickHandler={() => {
            dispatch(resetPrescriptionSchema())
            dispatch(resetSchema())
            setShowPopup(false)
            setStep(1)
          }}
          sxProp={{
            width: 116,
            height: 36,
            boxShadow:
              "0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2)",
          }}
        ></UButton>,
        <UButton
          key={t("newpatient.backbtnModal.stayOnPage")}
          variant="contained"
          btnText={t("newpatient.backbtnModal.stayOnPage")}
          sxProp={{
            width: 136,
            height: 36,
          }}
          onClickHandler={() => {
            setShowPopup(false)
          }}
        ></UButton>,
      ]}
    ></UModalPopup>
  )
}

export default LeavePagePopup
