import { ULoading } from '@/ui-component';
import { useOktaAuth } from '@okta/okta-react';
import { FC, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import LogOutFromUD from './LogOutFromUD';
import { clearLocalStorage } from '@/core/config/okta.config';

const QUERY_PARAM_SOURCE_UD = "ud"
const Logout: FC = () => {
  const { oktaAuth } = useOktaAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  // Implement logout logic here
  useEffect(() => {
    const isUDSource = searchParams?.get('source') === QUERY_PARAM_SOURCE_UD;
  
    const handleLogout = async () => {
      if (isUDSource) {
        await oktaAuth.tokenManager.clear(); // Clear stored tokens
        await oktaAuth.closeSession(); // End session without redirect
        clearLocalStorage()
      } else {
        await oktaAuth.signOut(); // Log out and redirect
      }
    };
  
    handleLogout();
  }, [oktaAuth, searchParams]);

  return (
    <>
      {(searchParams && searchParams.get('source')!=QUERY_PARAM_SOURCE_UD) ? (
        <ULoading isLoading={true} />
      ):(
        <LogOutFromUD/>
      )}
    </>
  )
};

export default Logout;
