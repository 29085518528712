import { FC, lazy, Suspense, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"

import { Alert, Box, Grid } from "../../../components/mui.components"
import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { ULoading, UText } from "../../../ui-component"
const ProgressBar = lazy(
  () => import("../../Patient/Overview/components/ProgressBar"),
)

const BundleProgressBar: FC<{
  isCurrentBundle: boolean
  isAlaCartePurched: boolean
  isBundleCompleted: boolean
}> = ({ isCurrentBundle, isAlaCartePurched, isBundleCompleted }) => {
  const location = useLocation()
  const { t } = useTranslation("common")
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { bundleList, remainingOrder, previouslyOrderedPricing } = useAppSelector(
    (state: RootState) => state.orderService,
  )
  const [currentBundle, setCurrentBundle] = useState(null);

  useEffect(()=>{
    if(!previouslyOrderedPricing || !bundleList) return
    
    const newCurrentBundle = bundleList.find((bundle)=>bundle.name.toLowerCase()===previouslyOrderedPricing.toLowerCase())
    if(newCurrentBundle){
      setCurrentBundle(newCurrentBundle)
    }
  },[previouslyOrderedPricing, bundleList])

  return (
    <Grid
      container
      sx={{
        padding: "16px 150px",
        background: "#fff",
        mb: 4,
        borderRadius: "16px",
      }}
    >
      {caseDetail.refinementRound > 0 &&
        location.pathname.includes("/bundle") && (
          <Alert severity="warning" sx={{ mb: 2, width: "100%" }}>
            {t("bundle.banner.warningTitle")}
          </Alert>
        )}
      <Box
        component={"div"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <UText variant="subtitle2" sxProp={{ py: 2 }}>
          {t("bundle.remainingToOrder")}
        </UText>
        <Box component={"div"} sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ mr: 1 }}>
            <UText variant={"body2"}>
              Aligners: {remainingOrder?.aligner?.remaining_count || 0} of{" "}
              {remainingOrder?.aligner?.total_count || 0}
            </UText>
            <Suspense fallback={<ULoading isLoading={true} />}>
              <ProgressBar
                colorCode={"primary.light"}
                total={remainingOrder?.aligner?.total_count}
                completed={remainingOrder?.aligner?.remaining_count}
              />
            </Suspense>
          </Box>
          <Box>
            <UText variant={"body2"}>
              Retainers: {remainingOrder?.retainer?.remaining_count || 0} of{" "}
              {remainingOrder?.retainer?.total_count || 0}
            </UText>
            <Suspense fallback={<ULoading isLoading={true} />}>
              <ProgressBar
                colorCode={"primary.light"}
                total={remainingOrder?.retainer?.total_count}
                completed={remainingOrder?.retainer?.remaining_count}
              />
            </Suspense>
          </Box>
        </Box>
      </Box>
      {isCurrentBundle && !isAlaCartePurched && !isBundleCompleted && currentBundle && (
        <Box
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <UText variant="subtitle2" sxProp={{ py: 2 }}>
            {t("bundle.remainingBundle")}
          </UText>
          <Box component={"div"} sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ mr: 1 }}>
              <UText variant={"body2"}>
                Aligners:{" "}
                {currentBundle?.description?.aligner?.included -
                  currentBundle?.description?.aligner?.consumed}{" "}
                of {currentBundle?.description?.aligner?.included}
              </UText>
              <Suspense fallback={<ULoading isLoading={true} />}>
                <ProgressBar
                  colorCode={"rgba(156, 39, 176, 1)"}
                  total={currentBundle?.description?.aligner?.included}
                  completed={
                    currentBundle?.description?.aligner?.included -
                    currentBundle?.description?.aligner?.consumed
                  }
                />
              </Suspense>
            </Box>
            <Box>
              <UText variant={"body2"}>
                Retainers:{" "}
                {currentBundle?.description?.retainer?.included -
                  currentBundle?.description?.retainer?.consumed}{" "}
                of {currentBundle?.description?.retainer?.included}
              </UText>
              <Suspense fallback={<ULoading isLoading={true} />}>
                <ProgressBar
                  colorCode={"rgba(156, 39, 176, 1)"}
                  total={currentBundle?.description?.retainer?.included}
                  completed={
                    currentBundle?.description?.retainer?.included -
                    currentBundle?.description?.retainer?.consumed
                  }
                />
              </Suspense>
            </Box>
          </Box>
        </Box>
      )}
    </Grid>
  )
}

export default BundleProgressBar
