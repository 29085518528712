import {createSlice, PayloadAction} from "@reduxjs/toolkit"

interface IAlertState {
  alertMsg: string
  isError: boolean
  btnText: string
  btnAction: () => void
  timeout: number
}

const initialState: IAlertState = {
  alertMsg: "",
  isError: false,
  btnText: "",
  btnAction: undefined,
  timeout: null,
}

export const alertSlice = createSlice({
  initialState,
  name: "alert",
  reducers: {
    closeAlert: (
      state,
      action
    ) => {
      state.alertMsg = ""
      state.btnText = ""
      state.btnAction = undefined
      state.timeout = null
    },
    setAlert: (
      state,
      action: PayloadAction<{
        message: string
        isError?: boolean
        btnText?: string
        btnAction?: () => void
        timeout?: number
      }>,
    ) => {
      state.alertMsg = action.payload.message
      state.isError = action.payload.isError || false
      state.btnText = action.payload.btnText || ""
      state.btnAction = action.payload.btnAction || undefined
      state.timeout = action.payload.timeout || null
    },
  },
})

export const { closeAlert, setAlert } = alertSlice.actions
export default alertSlice.reducer
