import { FC, useCallback, useEffect, useState } from "react"
import { Actions, ControlProps, JsonSchema } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"

const ArchElement: FC<{
  jsonSchema: JsonSchema
  inputKey: string
  subPropKeys: string[]
  data: any
  updateInput: (data: any) => void
}> = ({ jsonSchema, inputKey, subPropKeys, data, updateInput }) => {
  const [selectedChecks, setSelectedChecks] = useState(
    data ? { ...data[inputKey] } : {},
  )
  const [selected, setSelected] = useState<string>(
    data[inputKey]
      ? data[inputKey][subPropKeys[1]]
      : jsonSchema[subPropKeys[1]].default,
  )

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string)
    setSelectedChecks({
      ...selectedChecks,
      [event.target.name]: event.target.value,
    })
  }

  useEffect(() => {
    const obj = {}
    obj[inputKey] = {
      ...selectedChecks,
    }
    updateInput(obj)
  }, [selectedChecks, inputKey, updateInput])

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setSelectedChecks({
      ...selectedChecks,
      [event.target.name]: event.target.checked ? value : "",
    })
  }

  return (
    <>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-around",
          mb: selectedChecks[subPropKeys[0]] ? 0 : 2,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={!!selectedChecks[subPropKeys[0]]}
              onChange={(e) =>
                handleChangeInput(e, jsonSchema[subPropKeys[0]].label)
              }
              name={subPropKeys[0]}
            />
          }
          label={jsonSchema[subPropKeys[0]].label}
        />
        <FormControl sx={{ minWidth: 220 }}>
          <Select
            id={`${subPropKeys[1]}`}
            value={selected}
            name={`${subPropKeys[1]}`}
            onChange={handleChangeSelect}
          >
            {jsonSchema[subPropKeys[1]].enum?.map((e, i) => (
              <MenuItem key={`${e}+${i}`} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          flexDirection: "column",
          display: selectedChecks[subPropKeys[0]] ? "flex" : "none",
          mx: 4,
          mt: 0,
          mb: 1,
        }}
      >
        {jsonSchema[subPropKeys[2]].items.oneOf.map((item) => (
          <FormControlLabel
            key={item.const}
            control={
              <Checkbox
                checked={!!selectedChecks[item.const]}
                onChange={(e) => handleChangeInput(e, item.title)}
                name={item.const}
              />
            }
            label={item.title}
          />
        ))}
      </Box>
    </>
  )
}

const UArchesTreat: FC<ControlProps> = (props) => {
  const { handleChange, path, data, label, schema } = props
  const [isError, setIsError] = useState<boolean>(false)
  const [outJson, setOutJson] = useState<any>()
  const { core, dispatch } = useJsonForms()
  const [errors, setErrors] = useState("")
  const propsKeys = Object.keys(schema.properties)

  const checkArchesforRetainer = (data) => {
    if(!data) return
    const values = Object.values(data)

    const checkedItems = values.filter((item) => item.isChecked)

    const allAreRetainers = checkedItems.every(
      (item) => item.aligner_type === "Retainer Only",
    )

    return (
      (checkedItems.length === 1 && allAreRetainers) ||
      (checkedItems.length === 2 && allAreRetainers)
    )
  }
  useEffect(() => {
    const hasData =
      data &&
      ((data.upper && data.upper.isChecked) ||
        (data.lower && data.lower.isChecked))
    setIsError(!hasData)
    if (core?.schema.required?.indexOf(path) === -1) return
    if (hasData) {
      if (checkArchesforRetainer(data)) {
        setIsError(true)
        updateErrors("At least one arch must have an Aligner.")
      } else {
        updateErrors("")
      }
    } else {
      updateErrors("This is a required field")
    }
  }, [data])

  const updateErrors = (message: string) => {
    console.log("..Prescription path", isError, path)
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  useEffect(() => {
    let rs = { ...data, ...outJson }
    if (rs.lower && !rs.lower.isChecked) {
      delete rs.lower
    }
    if (rs.upper && !rs.upper.isChecked) {
      delete rs.upper
    }
    if (!rs.lower && !rs.upper) {
      rs = {}
    }
    handleChange(path, rs)
  }, [handleChange, outJson, path])

  const subProps = (key: string) => {
    return schema.properties[key]?.properties
  }

  const updateInput = useCallback((outData: any) => {
    setOutJson(outData)
  }, [])

  return (
    <>
      <FormControl
        error={isError && core?.validationMode === "ValidateAndShow"}
        component="fieldset"
        variant="standard"
        sx={{ my: 1 }}
      >
        <FormLabel
          sx={{
            my: 2,
            color: "rgba(0, 0, 0, 0.60)",
            "&.Mui-focused": {
              color: "rgba(0, 0, 0, 0.60)",
            },
          }}
        >
          {label}
        </FormLabel>
        {propsKeys &&
          propsKeys.map((p, index) => {
            return (
              <Box key={index}>
                <ArchElement
                  jsonSchema={subProps(p)}
                  inputKey={p}
                  subPropKeys={schema.archKeys}
                  updateInput={updateInput}
                  data={data}
                />
              </Box>
            )
          })}
        {isError && core?.validationMode === "ValidateAndShow" && (
          <FormHelperText sx={{ marginLeft: 0 }}>{errors}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

export default withJsonFormsControlProps(UArchesTreat)
