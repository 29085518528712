const UD_APP_VERSION = "10"
const UD_APP_DOWNLOAD_VERSION = "10.0.0"
const UD_APP_DI = "00860461001776"
const UD_APP_BUILD = "1"
export const UDApp = {
  version: UD_APP_VERSION,
  downloadable_version: UD_APP_DOWNLOAD_VERSION,
  about_version: `uDesign Software, FG0064 | Version: ${UD_APP_VERSION}`,
  about_build: `DI: ${UD_APP_DI} | Pl: uDesign ${UD_APP_VERSION} build ${UD_APP_BUILD}`,
  date: `2016 – ${new Date().getFullYear()}  © uLab Systems Inc.`,
  releaseNote: [
    {
      title: "New Features and Tools - Treatment Planning",
      list: [
        `
    <ol style="list-style-type: decimal; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
       <li style="display: list-item; padding: 0;">
          Bite Ramp function available on all teeth of both arches with ability to hide and show leveling plane,
          opposing arch and includes an interactive view when adjusting individual bite ramps—bite ramps auto populate
          on selected teeth and can be added and deleted at specific stages.
       </li>
       <li style="display: list-item; padding: 0;">
          Button and elastics: ability to select the start and end stage for each auxiliary.
       </li>
       <li style="display: list-item; padding: 0;">
          Bolton Analysis improvement.
       </li>
       <li style="display: list-item; padding: 0;">
          Course Refinement: drop down menu to view original treatment plan as well as any additional refinements.
       </li>
       <li style="display: list-item; padding: 0;">
          New pop-up warning when IPR is not staged prior to ordering or printing.
       </li>
       <li style="display: list-item; padding: 0;">
          Revised uAssist Home Screen status column.
       </li>
       <li style="display: list-item; padding: 0;">
          IDB improvements:
          <ul style="list-style-type: lower-alpha; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
             <li>Prescription builder</li>
             <li>Enhanced bracket library</li>
             <li>Preset bracket height position</li>
          </ul>
       </li>
    </ol>`,
      ],
    },
    {
      title: "New Features and Tools- In-Office Printing",
      list: [
        `
    <ol style="list-style-type: decimal; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
       <li style="display: list-item; padding: 0;">
          Ability to adjust the base height of the model on the output screen.
       </li>
    </ol>`,
      ],
    },
    {
      title: "New Features and Tools – Checkout & Ordering",
      list: [
        `
    <ol style="list-style-type: decimal; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
       <li style="display: list-item; padding: 0;">
          All uAssist case submissions and uAssist refinements will be submitted using uDesign Cloud.
       </li>
       <li style="display: list-item; padding: 0;">
          All uSmile orders will be submitted using uDesign Cloud.
       </li>
    </ol>`,
      ],
    },
    {
      title: "Removed Items",
      list: [
        `
    <ol style="list-style-type: none; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
       <li style="display: list-item; padding: 0;">
          The need to select stage for self-planned refinement.
       </li>
    </ol style="list-style-type: decimal; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">`,
      ],
    },
    {
      title: "Minor text changes and bug fixes",
      list: [
        `
    <ol style="list-style-type: none; list-style-position: outside; padding-left: 22px; font-size: 14px; color: #00000099 !important;">
       <li style="display: list-item; padding: 0;">
          The ability to add .75 offset on both smooth and semi-scallop trim line.
       </li>
    </ol>`,
      ],
    },
  ],
}
