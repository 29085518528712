import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { max } from "lodash"

import NavigationBlockPopup from "../../components/NavigationBlockPopup/NavigationBlockPopup"
import { useAppSelector } from "../../core/app/hooks"
import { updateCaseById } from "../../core/app/slices/case/caseThunkApi"
import { resetCase } from "../../core/app/slices/clinical/clinicalSlice"
import { setIsRouteChangeBlocked } from "../../core/app/slices/navigationPopup/navigationPopupSlice"
import { updateAlignerOrderPayload } from "../../core/app/slices/order"
import { placeAlignerOrders } from "../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../core/app/store"
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt"
import { useMyNavigation } from "../../hooks/useMyNavigation"
import { UButton } from "../../ui-component/components"
import { AlertColor, Box, Container } from "../../ui-component/mui.components"

import Bundle from "./Bundle/Bundle"
import Customization from "./Customization/Customization"
import Shipping from "./Shipping/Shipping"
import Stage from "./Stage/stage"
import ExitOrderAlertPopup from "./ExitOrderAlertPopup"
import {
  getCoverage,
  getSelectedTeeth,
  getThickness,
  maxRangeVal,
  retainerStageRange,
  sortStage,
  stageRange,
} from "./stage.helper"
import OrderSteps from "./stepper"
import { caseManagement, orderView, stagingManager } from "@/gluelayer"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"

const OrderModule: FC = () => {
  const { patientId, caseId } = useParams()
  const [isNextClicked, setIsNextClicked] = useState<boolean>(false)
  const navigate = useMyNavigation()
  const dispatch = useDispatch()
  const { t } = useTranslation("common")
  const [showAlert, setShowAlert] = useState<boolean>(false)

  const [stageAlertMessage, setstageAlertMessage] = useState<AlertColor>("info")
  const [customizationStep, setCustomizationStep] = useState<string>("1")
  const [cuStepTmp, setCuStep] = useState<number[]>([1])
  const steps = [
    {
      id: "bundle",
      label: "Select Bundle",
      to: `/order/patient/${patientId}/case/${caseId}/bundle`,
    },
    {
      id: "stage",
      label: "Select Stages",
      to: `/order/patient/${patientId}/case/${caseId}/stage`,
    },
    {
      id: "customization",
      label: "Customization",
      to: `/order/patient/${patientId}/case/${caseId}/customization`,
    },
    {
      id: "shipping",
      label: "Shipping & Packaging",
      to: `/order/patient/${patientId}/case/${caseId}/shipping`,
    },
  ]
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const {
    selectedBundle,
    shippingMethod,
    packageType,
    isAlignerCase,
    selectedShippingToAddressId,
    alignerOrderPayload,
    alignerOrderPayload: { stage, coverage, extra, recession, scanned },
    stageDetail,
    orderList,
  } = useAppSelector((state: RootState) => state.orderService)
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState<{
    [k: number]: boolean
  }>({ 0: false, 1: false, 2: false, 3: false })

  const location = useLocation()

  const {
    caseInfo: {
      remaining_order: {
        aligner: {
          upper: { total_count: up_aligner },
          lower: { total_count: low_aligner },
        },
        retainer: {
          upper: { total_count: up_retainer },
          lower: { total_count: low_retainer },
        },
      },
    },
  } = useAppSelector((state: RootState) => state.clinicalService)

  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  useEffect(() => {
    if (selectedBundle) {
      dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
    }
    if (location?.pathname.includes("/bundle")) {
      setActiveStep(0)
    } else if (location?.pathname.includes("/stage")) {
      setActiveStep(1)
    } else if (location?.pathname.includes("/customization")) {
      setActiveStep(2)
    } else if (location?.pathname.includes("/shipping")) {
      createAlignerOrderParams()
      setActiveStep(3)
    } else {
      setActiveStep(0)
    }
  }, [location, selectedBundle])
  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const createAlignerOrderParams = () => {
    let payload = {}
    if (selectedBundle) {
      payload = {
        order_type: "Aligner Order",
        case_id: +caseId,
        pricing_option: selectedBundle.name,
        shipping_method: shippingMethod,
        package_type: packageType,
        pricing_id: selectedBundle.id,
        shipping_address_id: selectedShippingToAddressId,
        quote: {
          ...selectedBundle,
        },
        aligner_case: isAlignerCase,
        upper_stage_range: "",
        lower_stage_range: "",
        upper_stage_count: 0,
        lower_stage_count: 0,
        upper_retainer_stage: "",
        lower_retainer_stage: "",
        upper_template_stage: "",
        lower_template_stage: "",
        aligner_material: "",
        thickness: "",
        upper_left_aligner_coverage: "Full",
        upper_right_aligner_coverage: "Full",
        lower_left_aligner_coverage: "Full",
        lower_right_aligner_coverage: "Full",
        upper_left_lasttoothtocover: "",
        upper_right_lasttoothtocover: "",
        lower_left_lasttoothtocover: "",
        lower_right_lasttoothtocover: "",
        extra_info: '{"keep_ramp":false}',
        complete_upper_stage: "",
        complete_lower_stage: "",
        complete_upper_retainer: "",
        complete_lower_retainer: "",
        complete_upper_template: "",
        complete_lower_template: "",
        txplan_name: "",
        txplan_subname: "",
      }

      if (stage) {
        if (caseDetail && caseDetail.udesign_json) {
          const udesign_json = JSON.parse(caseDetail.udesign_json)
          //Upper Aligner
          /**
           * when the case is Aligner
           * user selected stage and  retainer
           */
          if (
            stage.aligners.up &&
            stage.aligners.up.length > 0 &&
            udesign_json.UpperType === "A"
          ) {
            payload.upper_stage_range = sortStage(stage.aligners.up).join(",")
            payload.upper_stage_count = stage.aligners.up.length
            if (stage.retainers.up > 0) {
              payload.upper_retainer_stage = retainerStageRange(
                stage.retainers.up,
                500 +
                  Math.max(stagingManager.wasmStageData.keypointsUpMaxStep, 0) +
                  stagingManager.wasmStageData.upStartStep,
              )
            }
          }
          /**
           * when the case is Aligner
           * user selected only retainer and not selected ny stages
           */
          if (
            stage.aligners.up.length === 0 &&
            stage.retainers.up > 0 &&
            udesign_json.UpperType === "A"
          ) {
            payload.upper_retainer_stage = retainerStageRange(
              stage.retainers.up,
              500 +
                Math.max(stagingManager.wasmStageData.keypointsUpMaxStep, 0) +
                stagingManager.wasmStageData.upStartStep,
            )
          }

          //Upper Retainer
          /**
           * when the case is Retainer
           * user selected  retainer
           */
          if (udesign_json.UpperType === "R") {
            if (stage.retainers.up > 0) {
              payload.upper_retainer_stage = retainerStageRange(
                stage.retainers.up,
                500,
              )
            }
          }

          //upper Template
          /**
             0：400
             1：400
             2：401
             3：402 
           */
          if (stage.templates.up || stage.templates.up == 0) {
            if (stage.templates.up > 1) {
              payload.upper_template_stage = stage.templates.up + 400 + ""
            } else {
              payload.upper_template_stage = "400"
            }
          }

          //Lower Aligner
          /**
           * when the case is Aligner
           * user selected stage and  retainer
           */
          if (
            stage.aligners.low &&
            stage.aligners.low.length > 0 &&
            udesign_json.LowerType === "A"
          ) {
            payload.lower_stage_range = sortStage(stage.aligners.low).join(",")
            payload.lower_stage_count = stage.aligners.low.length

            if (stage.retainers.low > 0) {
              payload.lower_retainer_stage = retainerStageRange(
                stage.retainers.low,
                500 +
                  Math.max(
                    stagingManager.wasmStageData.keypointsDownMaxStep,
                    0,
                  ) +
                  stagingManager.wasmStageData.lowStartStep,
              )
            }
          }
          /**
           * when the case is Aligner
           * user selected only retainer and not selected ny stages
           */
          if (
            stage.aligners.low.length === 0 &&
            stage.retainers.low > 0 &&
            udesign_json.LowerType === "A"
          ) {
            payload.lower_retainer_stage = retainerStageRange(
              stage.retainers.low,
              500 +
                Math.max(stagingManager.wasmStageData.keypointsDownMaxStep, 0) +
                stagingManager.wasmStageData.lowStartStep,
            )
          }

          //Lower Retainer
          if (udesign_json.LowerType === "R") {
            if (stage.retainers.low > 0) {
              payload.lower_retainer_stage = retainerStageRange(
                stage.retainers.low,
                500,
              )
            }
          }

          //Lower Template
          /**
             0：400
             1：400
             2：401
             3：402 
           */
          if (stage.templates.low || stage.templates.low == 0) {
            if (stage.templates.low > 1) {
              payload.lower_template_stage = stage.templates.low + 400 + ""
            } else {
              payload.lower_template_stage = "400"
            }
          }
        }
      }

      if (coverage) {
        //radioLeft：[upper_left_aligner_coverage,lower_left_aligner_coverage]
        if (coverage.radioLeft) {
          payload.upper_left_aligner_coverage = getCoverage(
            coverage.radioLeft[0] + "",
          )
          payload.lower_left_aligner_coverage = getCoverage(
            coverage.radioLeft[1] + "",
          )
        }
        // radioRight: [upper_right_aligner_coverage,lower_right_aligner_coverage]

        if (coverage.radioRight) {
          payload.upper_right_aligner_coverage = getCoverage(
            coverage.radioRight[0] + "",
          )
          payload.lower_right_aligner_coverage = getCoverage(
            coverage.radioRight[1] + "",
          )
        }

        //selectedTeethid[upper_right_lasttoothtocover,upper_left_lasttoothtocover, lower_left_lasttoothtocover, lower_right_lasttoothtocover]
        if (coverage.selectedTeethid) {
          payload.upper_right_lasttoothtocover = getSelectedTeeth(
            coverage.selectedTeethid[0],
          )
          payload.upper_left_lasttoothtocover = getSelectedTeeth(
            coverage.selectedTeethid[1],
          )
          payload.lower_right_lasttoothtocover = getSelectedTeeth(
            coverage.selectedTeethid[3],
          )
          payload.lower_left_lasttoothtocover = getSelectedTeeth(
            coverage.selectedTeethid[2],
          )
        }
        if (coverage.material) {
          payload.aligner_material =
            coverage.material === "reva" ? "reva" : "zendura"
          payload.thickness =
            coverage.material !== "reva"
              ? getThickness(coverage.material + "")
              : "027"
        }
      }

      if (coverage.keepRamp) {
        payload.extra_info = '{"keep_ramp":true}'
      }

      if (caseDetail && caseDetail.udesign_json) {
        const udesign_json = JSON.parse(caseDetail.udesign_json)
        if (udesign_json.UpperMaxStageNum && udesign_json.UpperType === "A") {
          payload.complete_upper_stage = stageRange(
            stagingManager.wasmStageData.keypointsUpMaxStep,
            stagingManager.wasmStageData.upStartStep + 1,
          )
          // payload.complete_upper_retainer =
          //   500 + maxRangeVal(+udesign_json.UpperMaxStageNum) + ""
        }
        if (udesign_json.LowerMaxStageNum && udesign_json.LowerType === "A") {
          payload.complete_lower_stage = stageRange(
            stagingManager.wasmStageData.keypointsDownMaxStep,
            stagingManager.wasmStageData.lowStartStep + 1,
          )
          // payload.complete_lower_retainer =
          //   500 + maxRangeVal(+udesign_json.LowerMaxStageNum) + ""
        }

        //TBD:after verifiying UD code  reainer values are 500 default
        if (udesign_json.UpperType === "R" || udesign_json.UpperType === "A") {
          payload.complete_upper_retainer = "500"
        }
        //TBD:after verifiying UD code  reainer values are 500 default
        if (udesign_json.LowerType === "R" || udesign_json.LowerType === "A") {
          payload.complete_lower_retainer = "500"
        }

        if (udesign_json.UpperTemplate > 0) {
          payload.complete_upper_template = "400"
        }
        if (udesign_json.LowerTemplate > 0) {
          payload.complete_lower_template = "400"
        }
      }

      if (selectedBundle.name === "A La Carte") {
        payload.pricing_option = "Alacarte"
        payload.pricing_id = ""
      }
      if (selectedBundle.name === "2 Retainers") {
        payload.pricing_option = "2 Retainer"
      }
      if (selectedBundle.name === "4 Retainers") {
        payload.pricing_option = "4 Retainer"
      }

      if (caseDetail.txplanName || caseDetail.txplanSubname) {
        payload.txplan_name = caseDetail.txplanName || ""
        payload.txplan_subname = caseDetail.txplanSubname || ""
      }

      dispatch(updateAlignerOrderPayload(payload))

      //Testing Purpose  we will remove it later
      console.log(
        "...udesign_json...",
        caseDetail.udesign_json,
        "... extra...",
        extra,
        "...recessionfill",
        recession,
        "...scanned...",
        scanned,
        "...coverage...",
        alignerOrderPayload.coverage,
        ".dddddd.final .payload",
        payload,
      )
    }
    return payload
  }

  const getStageValues = () => {
    let upperPrintArr = []
    let lowerPrintArr = []
    let upperRetainer = 0
    let lowerRetainer = 0
    let upperTemplate = 0
    let lowerTemplate = 0
    if (orderList && orderList[caseDetail?.id]?.length > 0) {
      orderList[caseDetail.id].forEach((res) => {
        if (res?.upperStageRange) {
          upperPrintArr.push(...res.upperStageRange.split(","))
        }
        if (res?.lowerStageRange) {
          lowerPrintArr.push(...res.lowerStageRange.split(","))
        }
        if (res?.upperRetainerCount) upperRetainer = 1

        if (res?.lowerRetainerCount) lowerRetainer = 1

        if (res?.upperTemplateStage) upperTemplate = 1

        if (res?.lowerTemplateStage) lowerTemplate = 1
      })
    }

    upperPrintArr = [...upperPrintArr, ...alignerOrderPayload.stage.aligners.up]
    lowerPrintArr = [
      ...lowerPrintArr,
      ...alignerOrderPayload.stage.aligners.low,
    ]
    if (stage.retainers.up) upperRetainer = 1
    if (stage.retainers.low) lowerRetainer = 1
    if (stage.templates.up || stage.templates.up == 0) upperTemplate = 1
    if (stage.templates.low || stage.templates.low == 0) lowerTemplate = 1

    return {
      upper: upperPrintArr.length ? Math.max(...upperPrintArr.map(Number)) : 0,
      lower: lowerPrintArr.length ? Math.max(...lowerPrintArr.map(Number)) : 0,
      uRetainer: upperRetainer,
      lRetainer: lowerRetainer,
      uTemplate: upperTemplate,
      lTemplate: lowerTemplate,
    }
  }

  const updateJsonToBulk2Zip = () => {
    const elasticvalue = orderView.getElasticJson(scanned)
    caseManagement
      .saveJsonDataToZip(
        elasticvalue,
        "elasticControlPoints.json",
        "Setting Data2",
      )
      .then((bool) => {
        if (bool) {
          caseManagement.getCaseFiles(["bulk2"]).then((r) => {
            const zips = []
            if (r) {
              for (const k in r) {
                zips.push({
                  fileName: k,
                  file: r[k],
                })
              }
              dispatch(
                uploadCaseZips({
                  zips,
                  patientId,
                  caseId,
                }),
              )
            }
          })

          console.log(
            "elasticControlPoints.json has been successfully deposited to the virtual directory!",
          )
        } else {
          console.log(
            "Failed to store elasticControlPoints.json to virtual directory!",
          )
        }
      })
  }

  const submitAlignerOrder = () => {
    const param = createAlignerOrderParams()
    const { upper, lower, uRetainer, uTemplate, lRetainer, lTemplate } =
      getStageValues()
    dispatch(placeAlignerOrders(param)).then((_res) => {
      //update recessionfill
      if (caseDetail && caseDetail.udesign_json) {
        const udesign_json = JSON.parse(caseDetail.udesign_json)
        const recessionfill = []
        // eslint-disable-next-line prettier/prettier
        recession && recession.forEach((x) => recessionfill.push({ id: x }))
        const newUdesignJsonObj = {
          ...udesign_json,
          recessionfill:
            recession && recession.length ? JSON.stringify(recessionfill) : "",
          UpperPrintStageNum: upper,
          LowerPrintStageNum: lower,
          LowerRetainer: lRetainer,
          UpperRetainer: uRetainer,
          UpperTemplate: uTemplate,
          LowerTemplate: lTemplate,
        }
        if (scanned) {
          updateJsonToBulk2Zip()
        }
        dispatch(
          updateCaseById({
            patientId,
            caseId,
            payload: {
              case_extra_attrs: JSON.stringify(newUdesignJsonObj),
            },
          }),
        )
      }

      navigate(`/order/patient/${patientId}/case/${caseId}/revieworder`)
    })
  }

  const handleNext = async () => {
    await dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    setIsNextClicked(true)
    if (
      !selectedBundle &&
      (!selectedBundle || (selectedBundle && !selectedBundle.sku_name))
    ) {
      // to scroll top to see warning
      window.scrollTo({
        top: 50,
        behavior: "smooth",
      })
      return
    }
    if (activeStep === 2) {
      if (cuStepTmp.length < 3) {
        if (cuStepTmp.includes(1) && cuStepTmp.includes(2)) {
          setCustomizationStep("3")
          setCuStep([1, 2, 3])
        } else if (cuStepTmp.includes(1) && cuStepTmp.includes(3)) {
          setCustomizationStep("2")
          setCuStep([1, 2, 3])
        } else {
          setCustomizationStep("2")
          setCuStep([1, 2])
        }
        return
      }
    }
    if (activeStep === 3) {
      submitAlignerOrder()
      return
    }
    if (activeStep === 1) {
      const isStage = validateStageSelection()
      if (!isStage) {
        setstageAlertMessage("error")
        return
      } else {
        setstageAlertMessage("info")
      }
    }
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
    handleComplete()
    if (activeStep === 2) {
      setCustomizationStep("3")
    }
    navigate(steps[activeStep + 1].to)
  }

  const handleBack = async () => {
    await dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    if (activeStep === 0) {
      setShowAlert(true)
    } else if (activeStep === 2) {
      if (customizationStep !== "1") {
        const step = +customizationStep
        setCustomizationStep("" + (step - 1))
        return
      } else {
        setCustomizationStep("1")
        setCuStep([1])
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
        navigate(steps[activeStep - 1].to)
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
      navigate(steps[activeStep - 1].to)
    }
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    // handleNext()
  }

  useEffect(() => {
    if (!stage || !selectedBundle) return
    const selectedAligners =
        stage.aligners.up.length + stage.aligners.low.length || 0,
      selectedRetainers = stage.retainers.up + stage.retainers.low || 0
    if (selectedAligners === 0 && selectedRetainers === 0) {
      setstageAlertMessage("info")
    }

    let aligners = 0
    let retainers = 0
    //when selected bundle
    if (selectedBundle.description) {
      if (selectedBundle.description.retainer) {
        retainers = selectedBundle.description.retainer.included
      }
      if (selectedBundle.description.aligner) {
        aligners = selectedBundle.description.aligner.included
      }
    }

    //when bundle as alacarte
    if (!selectedBundle.description && selectedBundle.retainer) {
      retainers = selectedBundle.retainer.item_count
    }
    //when bundle as alacarte
    if (!selectedBundle.description && selectedBundle.aligner) {
      aligners = selectedBundle.aligner.item_count
    }
    const bundle = { aligners, retainers }
    let consumed_aligner = 0,
      consumed_retainer = 0,
      consumed_template = 0
    for (let index = 0; index < stageDetail.length; index++) {
      const { stage } = stageDetail[index]
      if (!stage) continue
      if (stage < 400) ++consumed_aligner
      else if (stage < 500) ++consumed_template
      else ++consumed_retainer
    }
    const total_aligner = consumed_aligner + selectedAligners
    const total_retainer = consumed_retainer + selectedRetainers
    const isBundleAlignerCompleted = consumed_aligner >= bundle.aligners
    const isBundleretainerCompleted = consumed_retainer >= bundle.retainers
    if (
      ((isBundleAlignerCompleted && selectedAligners > 0) ||
        (!isBundleAlignerCompleted && selectedAligners > bundle.aligners) ||
        (!isBundleretainerCompleted && selectedRetainers > bundle.retainers)) &&
      selectedBundle.name !== "A La Carte"
    ) {
      setstageAlertMessage("warning")
    } else if (
      total_aligner <= bundle.aligners ||
      total_retainer <= bundle.retainers
    ) {
      setstageAlertMessage("info")
    }
  }, [stage, selectedBundle])

  const validateStageSelection = () => {
    const selectedAligners =
        stage.aligners.up.length + stage.aligners.low.length || 0,
      selectedRetainers = stage.retainers.up + stage.retainers.low || 0
    if (
      selectedAligners === 0 &&
      selectedRetainers === 0 &&
      stage.templates.up === null &&
      stage.templates.low === null
    ) {
      return false
    } else {
      return true
    }
  }
  //Clean up clincal zipNames, zipList, and other data to avoid entering clincal with errors
  useEffect(() => {
    return () => {
      dispatch(resetCase())
    }
  }, [])

  return (
    <>
      <OrderSteps activeStep={activeStep} steps={steps} completed={completed} />
      <Box
        sx={{
          minHeight: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Routes>
          <Route
            path={"bundle"}
            element={<Bundle isSubmitted={isNextClicked} />}
          />
          <Route
            path={"stage"}
            element={
              <Stage
                alertMessage={stageAlertMessage}
                setStageAlertMessage={setstageAlertMessage}
              />
            }
          />
          <Route
            path={"customization"}
            element={
              <Customization
                nextCustomizationStep={customizationStep}
                cuStepTmp={cuStepTmp}
                setCuStep={setCuStep}
              />
            }
          />
          <Route path={"shipping"} element={<Shipping />} />
          <Route path={"*"} element={<Navigate to={"bundle"} replace />} />
        </Routes>
      </Box>
      <Container sx={{ mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <UButton
            variant="outlined"
            onClickHandler={handleBack}
            sxProp={{ width: "96px", height: "36px" }}
            startIcon={
              <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
            }
            btnText={t("button.back")}
          />
          <Box sx={{ flex: "1 1 auto" }} />
          {activeStep === 3 ? (
            <UButton
              variant="contained"
              onClickHandler={handleNext}
              sxProp={{ width: "109px", height: "36px" }}
              endIcon={
                <ChevronRightIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
              }
              btnText={t("button.review")}
            />
          ) : (
            <UButton
              variant="contained"
              onClickHandler={handleNext}
              sxProp={{ width: "92px", height: "36px" }}
              endIcon={
                <ChevronRightIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
              }
              btnText={t("button.next")}
            />
          )}
        </Box>
      </Container>
      <ExitOrderAlertPopup
        showAlert={showAlert}
        closeAlert={() => {
          dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
          setShowAlert(false)
        }}
        buttonTxt={t("button.back")}
      />
      <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default OrderModule
